import {
	type LoaderFunctionArgs,
	type HeadersArgs,
	Outlet,
	useNavigate,
	useSearchParams,
	data,
	useLoaderData,
} from 'react-router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Chip from '~/components/chip/Chip'
import { NavLink } from '~/components/internal-nav-link/Internal-nav-link'
import { LocationInfoModal } from '~/components/location-info-modal/location-info-modal'
import MapShowBtn from '~/components/map/map-show-btn'
import { MultiItemSwiper } from '~/components/swiper/multi-item-swiper'
import { getCityCount } from '~/queries/getCityCount.server'
import { cachified, lruCache } from '~/utils/cache/cache.server'
import { getLanguageFromCountryCode, useClientLang } from '~/utils/i18n.utils'
import { setUserCoordinates } from '~/utils/location/location'
import { getUserLocation } from '~/utils/location/location.server'
import { capitalizeText } from '~/utils/misc'
import { makeTimings, time } from '~/utils/timing.server'

export async function loader({ params, request }: LoaderFunctionArgs) {
	const timings = makeTimings('place loader')
	const userLocation = await getUserLocation(request, params)

	const cities = await time(
		() =>
			cachified({
				key: `root:cities`,
				cache: lruCache,
				timings,
				ttl: 1000 * 60 * 30, // 30 minutes
				getFreshValue: async () => {
					return await getCityCount()
				},
			}),
		{ timings, type: 'get cities' },
	)

	return data(
		{ cities, userLocation },
		{ headers: { 'Server-Timing': timings.toString() } },
	)
}

export function headers({ parentHeaders, loaderHeaders }: HeadersArgs) {
	parentHeaders.append('Server-Timing', loaderHeaders.get('Server-Timing')!)
	return parentHeaders
}

export default function Index() {
	const { cities, userLocation } = useLoaderData<typeof loader>()
	const [searchParams] = useSearchParams()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const lang = useClientLang().lngShort
	const sp = new URLSearchParams(searchParams.toString())
	sp.delete('swLat')
	sp.delete('swLng')
	sp.delete('neLat')
	sp.delete('neLng')
	sp.delete('page')
	sp.delete('radius')
	sp.delete('nearby')
	sp.delete('city')
	sp.delete('country')

	const [showLocationInfoModal, setShowLocationInfoModal] = useState(false)
	const [haveAskedUserCoordinates, setHaveAskedUserCoordinates] =
		useState(false)
	const askUserLocation = async () => {
		await setUserCoordinates()
			.then(() => {
				navigate(
					`${lang === 'sv' ? '' : `/${lang}`}/place?nearby=true` +
						(sp.size > 0 ? '&' + sp.toString() : ''),
				)
			})
			.catch(() => setShowLocationInfoModal(true))
	}
	useEffect(() => {
		if (
			!haveAskedUserCoordinates &&
			!userLocation.havePosition &&
			userLocation.nearbySelected
		) {
			askUserLocation()
			setHaveAskedUserCoordinates(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [haveAskedUserCoordinates, userLocation])

	const placeList = cities.map(item => (
		<NavLink
			to={`/place/${item.countryCode}/${item.city}${
				sp.size > 0 ? '?' + sp.toString() : ''
			}`}
			key={item.city}
			className={`m-1 inline-block rounded-full focus:outline-1`}
			tabIndex={-1}
		>
			{({ isActive }) => (
				<>
					<Chip
						text={
							<div className="flex items-center">
								<img
									src={`/locales/${getLanguageFromCountryCode(item.countryCode.toUpperCase())}/1x1.svg`}
									className="mr-1 h-4 w-4 rounded-full"
								/>
								{capitalizeText(item.city)}
							</div>
						}
						color={isActive ? 'cardActive' : 'card'}
						size="md"
						className="cursor-pointer border border-border duration-100 hover:border-foreground/50"
					/>
				</>
			)}
		</NavLink>
	))
	placeList.unshift(
		<>
			<button
				onClick={askUserLocation}
				className={`m-1 inline-block rounded-full focus:outline-1`}
				tabIndex={-1}
			>
				<Chip
					text={t('location_near_you')}
					color={
						userLocation.havePosition &&
						userLocation.radius &&
						userLocation.nearbySelected
							? 'cardActive'
							: 'card'
					}
					size="md"
					className="cursor-pointer border border-border duration-100 hover:border-foreground/50"
				/>
			</button>
		</>,
	)

	return (
		<>
			<div className="mt-20 border-b border-border pb-2.5 pt-2.5 max-md:!px-2 md:pt-1">
				<MultiItemSwiper list={placeList} />
			</div>

			<Outlet />

			<MapShowBtn toMap />
			<LocationInfoModal
				open={showLocationInfoModal}
				setOpen={setShowLocationInfoModal}
			/>
		</>
	)
}
