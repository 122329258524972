import { useState, type JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Icon } from '../icon/icon'

export function MultiItemSwiper({ list }: { list: JSX.Element[] }) {
	const [prevHidden, setPrevHidden] = useState(true)
	const [nextHidden, setNextHidden] = useState(false)
	return (
		<Swiper
			slidesPerView={'auto'}
			spaceBetween={0}
			slidesPerGroup={4}
			freeMode
			modules={[FreeMode]}
			className="!mx-0 !px-0 md:!mx-4 md:!px-4 lg:!mx-6 lg:!px-6 xl:!mx-10 xl:!px-10"
			onReachBeginning={() => setPrevHidden(true)}
			onReachEnd={() => setNextHidden(true)}
			onFromEdge={() => {
				setPrevHidden(false)
				setNextHidden(false)
			}}
		>
			{list.map((item, index) => (
				<SwiperSlide key={index} className="!w-auto">
					{item}
				</SwiperSlide>
			))}
			{!prevHidden && <SlidePrevButton />}
			{!nextHidden && <SlideNextButton />}
		</Swiper>
	)
}

function SlidePrevButton() {
	const swiper = useSwiper()
	const { t } = useTranslation()
	return (
		<>
			{swiper.activeIndex != 1 && (
				<button
					aria-label={t('misc_previous_page')}
					className="absolute left-0 top-1/2 z-10 -translate-y-1/2 transform rounded-md bg-gradient-to-l from-transparent via-background/90 to-background py-2 pr-5 text-foreground/60 max-md:hidden"
					onClick={() => swiper.slidePrev()}
					tabIndex={-1}
				>
					<Icon name="chevron-down" size="xl" className="rotate-90" />
				</button>
			)}
		</>
	)
}

function SlideNextButton() {
	const swiper = useSwiper()
	const { t } = useTranslation()
	return (
		<button
			aria-label={t('misc_next_page')}
			className="absolute right-0 top-1/2 z-10 -translate-y-1/2 transform rounded-md bg-gradient-to-r from-transparent via-background/90 to-background py-2 pl-5 text-foreground/60 max-md:hidden"
			onClick={() => swiper.slideNext()}
			tabIndex={-1}
		>
			<Icon name="chevron-down" size="xl" className="-rotate-90" />
		</button>
	)
}
